<template>
  <div class="asset-container">
    <div class="profile-row" v-if="userData != null">
      <div class="column-1">
        <div class="profile-header">
          <div class="profile-cover"></div>
          <div class="profile-picture">
            <img :src="userData.profile" />
          </div>
        </div>
        <div class="profile-information">
          <div class="profile-rating">
            <v-row style="justify-content: center; margin-right: 10px">
              <v-col lg="1" v-for="n in 5" :key="n">
                <v-icon color="yellow darken-3" size="20">mdi-star</v-icon>
              </v-col>
            </v-row>
          </div>
          <h3 class="name">{{ userData.name }}</h3>
          <p class="designation">{{ userData.role }}</p>
          <p class="country mt-1">
            <v-icon size="15" color="white">mdi-map-marker</v-icon>
            {{ userData.country }}
          </p>
          <div class="divider"></div>
        </div>
        <div class="social-information">
          <div class="social-container">
            <p>YEARS OF EXPERIENCE</p>
            <div>{{ userData.yearsOfExperience }} Years</div>
          </div>
          <div class="social-container">
            <p>PORTFOLIO LINK</p>
            <div>
              <a :href="userData.portfolio" target="_blank">Portfolio</a>
            </div>
          </div>
          <div class="social-container">
            <p>SOCIAL LINK</p>
            <div class="linkedin">
              <v-icon color="white" size="20" class="mr-1">mdi-linkedin</v-icon>
              <a :href="userData.workUrl">LinkedIn</a>
            </div>
          </div>
        </div>
      </div>
      <div class="column-2" v-if="tokenDetails != null">
        <v-row>
          <v-col lg="6">
            <h3 class="general-text-title text">{{ tokenDetails.name }}</h3>
            <p class="desc">{{ tokenDetails.description }}</p>
            <h3 class="general-text-title sub-text mt-10">Asset Quotation</h3>
            <v-row>
              <v-col lg="2">
                <div class="request-asset-nature">
                  <div class="asset-nature">
                    <v-icon color="#ff7f3f" size="20">mdi-currency-usd</v-icon>
                    <p>{{ tokenDetails.price }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              class=""
              style="justify-content: flex-start; padding-top: 15%"
            >
              <v-col lg="5">
                <button class="primary">BUY ASSET</button>
              </v-col>
              <v-col lg="5">
                <button class="secondary-btn">LEASE ASSET</button>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <div class="request-img">
              <model-viewer
                :src="tokenDetails.image"
                ar-modes="webxr scene-viewer quick-look"
                seamless-poster
                shadow-intensity="1"
                camera-controls
                ar
                auto-rotate
                loading="eager"
                style="height: 100%; width: 100%"
                v-if="tokenDetails.image.length !== 0"
              >
              </model-viewer>
              <img
                :src="tokenDetails.placeholder"
                style="width: 100%; height: 100%; object-fit: contain"
                v-else
              />
            </div>
          </v-col>
        </v-row>
        <v-row style="width: 100%">
          <v-col lg="2">
            <div class="request-asset-nature mt-10">
              <p>Asset Environment</p>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  lg="12"
                  v-for="(data, index) in tokenDetails.selectedAssetType"
                  :key="index"
                >
                  <div class="asset-nature">
                    <v-icon color="#ff7f3f" size="20">mdi-application</v-icon>
                    <p>{{ data }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col lg="10">
            <div class="request-asset-nature mt-10">
              <p>Asset Categories</p>
              <v-row>
                <v-col
                  lg="3"
                  cols="12"
                  sm="6"
                  v-for="(cat, index) in tokenDetails.assetCategories"
                  :key="index"
                >
                  <div class="asset-nature">
                    <v-icon color="#ff7f3f" size="20">mdi-tag</v-icon>
                    <p>{{ cat }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
        <v-row style="width: 100%">
          <v-col lg="4">
            <div class="request-asset-nature mt-6">
              <p>Available File Formats</p>
              <v-row>
                <v-col
                  lg="4"
                  cols="12"
                  sm="6"
                  v-for="(cat, index) in tokenDetails.availableFileFormats"
                  :key="index"
                >
                  <div class="asset-nature">
                    <v-icon color="#ff7f3f" size="20">mdi-file</v-icon>
                    <p>{{ cat }}</p>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import PropertyCard from "../components/Common/PropertyCard.vue";
import { mapState } from "vuex";
import Header from "../components/Header.vue";
import WalletCard from "../components/Common/WalletCard.vue";
import { assetObj } from "../services/asset-service";
import { mailServiceObj } from "../services/mail-service";
import Loader from "../components/Loader/Loader.vue";
import StatusModel from "../components/Modals/StatusModel.vue";
import { contactObj } from "../services/contact-service";
import { uploadAssetToIPFS } from "../helpers/ipfs-upload";
import { createUserAssets } from "../helpers/asset-contract";
import { userObj } from "../services/user-service";

export default {
  name: "ViewAssetPublic",
  components: {
    PropertyCard,
    Header,
    WalletCard,
    Loader,
    StatusModel,
  },
  created() {
    this.getTokenDetails();
  },
  data() {
    return {
      tokenDetails: null,
      isLoading: false,
      loaderText: "Verifying Asset....",
      isSuccess: false,
      successPopup: false,
      contractAddress: "",
      walletAddress: "",
      statusTitle: "",
      statusMessage: "",
      showRentModal: false,
      messages: [],
      showMintModal: false,
      price: 0,
      showChat: false,
      showEditPopup: false,
      showWarning: false,
      editPriceModal: false,
      userData: null,
    };
  },
  methods: {
    async getTokenDetails() {
      try {
        this.loaderText = "Loading Asset Details....";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        const type = this.$route.query.type;
        if (this.isConnected && type === "chain") {
          const res = await assetObj.getAssetById(tokenId);
          this.tokenDetails = res;
          this.tokenDetails.verified = res.isVerified;
          this.tokenDetails.assetCategories = res.category;
          this.tokenDetails.fileFormats = res.fileFormats;
          this.tokenDetails.availableFileFormats = res.fileFormats;
        } else {
          const res = await assetObj.getLazyMintById(tokenId);
          this.tokenDetails = res;
          this.userData = res.user;
          this.tokenDetails.isVerified = res.verified;
          this.tokenDetails.seller = res.user.uid;
          this.tokenDetails.fileFormats = res.availableFileFormats;
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Fetching Asset Details";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    async copyButtonUrl() {
      const url = `${window.location.origin}/creator/asset/${this.tokenDetails.tokenId}`;
      await navigator.clipboard.writeText(url);
    },
    close() {
      this.successPopup = false;
      this.showRentModal = false;
      this.showMintModal = false;
      this.showChat = false;
      this.showEditPopup = false;
      this.showWarning = false;
      this.editPriceModal = false;
    },
    async gotToUserProfile(seller_id) {
      this.$router.push(`/user/${seller_id}`);
    },
    async mintRequest() {
      try {
        this.loaderText = "Sending Minting Request....";
        this.isLoading = true;
        await mailServiceObj.sendMailToAdminOnceNewMintRequestCreated(
          this.user.name
        );
        const tokenId = this.$route.params.tokenId;
        await assetObj.mintRequestStatus(tokenId);
        this.messages.push({
          message: "Needed to mint this asset with the id: " + tokenId + " ",
          type: "user",
          user: this.user,
        });
        const jsonData = {
          messages: this.messages,
          userId: this.user.uid,
          sentTime: new Date(),
        };
        await contactObj.sendMessage(jsonData);
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Mint Request Sent";
        this.statusMessage =
          "Your Mint Request has been sent to the admin. You will be notified once the admin approves your request.";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Sending Mint Request";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    checkProfile() {
      this.$router.push(`/profile/public/${this.tokenDetails.user.uid}`);
    },
    async acceptToken() {
      try {
        this.loaderText = "Accepting Asset....";
        this.isLoading = true;
        await mailServiceObj.sendMailToAdminOnceNewMintRequestCreated(
          this.user.name
        );
        const tokenId = this.$route.params.tokenId;
        const type = this.$route.query.type;
        if (this.isConnected && type === "chain") {
          await assetObj.verifyUserAsset(tokenId);
        } else {
          await assetObj.updateTokenId(tokenId, this.tokenDetails);
        }
        window.location.reload();
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Accepting Asset....";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    async updateToken() {
      try {
        this.loaderText = "Sending Request....";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        if (this.tokenDetails.requests === undefined) {
          this.tokenDetails.requests = [
            {
              userId: this.user.uid,
              user: this.user,
            },
          ];
        } else {
          const user = this.tokenDetails.requests.filter(
            (data) => data.userId === this.user.uid
          );
          if (user.length === 0) {
            this.tokenDetails.requests.push({
              userId: this.user.uid,
              user: this.user,
            });
          }
        }
        await assetObj.updateLazyMint(tokenId, this.tokenDetails);
        await mailServiceObj.sendMailToAdminWhenOwnerOfferMade(
          this.tokenDetails,
          this.user
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Asset Request Sent...";
        this.statusMessage =
          "Your Request Have been submitted. The team will get back to you";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
          window.location.reload();
        }, 3000);
      } catch (error) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Error Sending Request...";
        this.statusMessage = error.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 3000);
      }
    },
    async leaseAssetToSigna() {
      try {
        this.loaderText = "Leasing Asset....";
        this.isLoading = true;
        const tokenId = this.$route.params.tokenId;
        const type = this.$route.query.type;
        await assetObj.leaseTokenToSigna(
          tokenId,
          this.tokenDetails.assetAddress
        );
        this.isLoading = false;
        this.isSuccess = true;
        this.statusTitle = "Asset Leased Successfully";
        this.statusMessage = "";
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      } catch (err) {
        this.isLoading = false;
        this.isSuccess = false;
        this.statusTitle = "Asset Leasing Failed";
        this.statusMessage = err.message;
        this.successPopup = true;
        setTimeout(() => {
          this.successPopup = false;
        }, 2000);
      }
    },
    showMintPopup() {
      this.showMintModal = true;
    },
    async mintNow() {
      try {
        this.loaderText = "Minting Asset...";
        this.isLoading = true;
        const url = await uploadAssetToIPFS(this.tokenDetails);
        if (url) {
          const transactionData = await createUserAssets(
            this.tokenDetails,
            url
          );
          if (transactionData) {
            const mintedAsset = await assetObj.getUserNonVerified();
            if (mintedAsset.length > 0) {
              const tokenId = this.$route.params.tokenId;
              await assetObj.deleteDocument(tokenId);
              this.isLoading = false;
              this.tokenDetails.tokenId =
                mintedAsset[mintedAsset.length - 1].tokenId;
              this.statusTitle = "Asset Minted Successful";
              this.statusMessage =
                "You asset minting successful, the team will verify the asset";
              this.isSuccess = true;
              this.successPopup = true;
              setTimeout(() => {
                this.successPopup = false;
              }, 2000);
              this.$router.push("/wallet");
            }
          }
        }
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    showPopupForRent() {
      this.showRentModal = true;
    },
    async onClickEvent() {
      const tokenId = this.$route.params.tokenId;
      const userId = this.tokenDetails.userId;
      let userData = await userObj.getUser(userId);
      let userCollectionData = userData.data();
      let assetsCreated = userCollectionData.assetCreated;
      assetsCreated -= 1;
      userCollectionData.uid = userId;
      userCollectionData.assetCreated = assetsCreated;
      await userObj.updateUserData(userCollectionData);
      await assetObj.deleteDocument(tokenId);
      this.$router.push("/profile?tab=2");
    },
    goTo() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.asset-container {
  width: 100%;
  height: 100vh;
  background: #0b0c10;
}
.profile-row {
  width: 100%;
  justify-content: space-between;
  display: flex;
  padding: 20px;
}
.column-1 {
  flex: 0.22;
  height: 95vh;
  background-color: #1b1b23;
  border-radius: 15px;
  padding: 0px 0px 0px 0px;
}
.profile-header {
  width: 100%;
  height: 25vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  position: relative;
}
.profile-cover {
  width: 100%;
  height: 18vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-image: url("../assets/profile/cover_update.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  border: 5px solid white;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
}
.profile-picture > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-information {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.column-2 {
  flex: 0.75;
  height: 95vh;
  border-radius: 15px;
  margin-left: 0px;
  padding: 10px 10px 10px 10px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.profile-rating {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.name {
  margin: 20px 0px 0px 0px;
  color: white;
}
.designation {
  color: #e0e0e0;
  margin: 5px 0px 0px 0px;
  font-weight: 200;
  font-size: 12px;
}
.country {
  color: #e0e0e0;
  margin: 10px 0px 20px 0px;
  font-weight: 200;
  font-size: 12px;
}
.divider {
  width: 70%;
  height: 2px;
  background: rgba(196, 196, 196, 0.31);
}
.social-information {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.social-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}
.social-container > p {
  color: #e0e0e0;
  font-size: 10px;
  font-weight: 600;
  margin: 0px 0px 5px 0px;
  text-align: left;
  width: 80%;
}
.social-container > div {
  width: 80%;
  height: 30px;
  color: #ffbf3f;
  background-color: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  border-radius: 5px;
}
a {
  color: #ffbf3f;
  text-decoration: none;
}
.linkedin {
  display: flex;
  width: 100%;
}
/*  */
.text {
  font-size: 30px !important;
}
.desc {
  width: 100%;
  margin: 0px;
}
.sub-text {
  font-size: 20px !important;
}

.request-asset-nature {
  margin-top: 0px;
}

.request-asset-nature > p {
  margin: 0px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
  text-transform: uppercase;
}

.asset-nature {
  padding: 5px;
  background: rgba(217, 217, 217, 0.1);
  text-align: center;
  border-radius: 5px;
  border: 0.5px solid #ff7f3f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.asset-nature > p {
  font-size: 14px;
  color: white;
  margin: 0px;
  font-weight: 400;
  margin-left: 5px;
}
.request-img {
  width: 100%;
  height: 300px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(224, 224, 224, 0.14);
  position: relative;
}

.request_image {
  width: 70%;
  height: 100%;
  border-radius: 15px;
  object-fit: contain;
}
.verification-tab {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.623);
  padding: 5px;
  border-radius: 5px;
}

.status {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: linear-gradient(
    97.3deg,
    #ffbf3f -2.55%,
    #ff7f3f 16.2%,
    #ff3f7f 88.17%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5px;
}

.status-text {
  color: white;
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text; */
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  margin-left: 8px;
}
.primary {
  background: linear-gradient(
    132.65deg,
    #ffbf3f 8.83%,
    #ff7f3f 65.1%,
    #ff3f7f 104.55%
  );
  font-family: "Archivo", sans-serif;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  height: 35px;
}
.secondary-btn {
  border: 1px solid var(--border-color);
  font-family: "Archivo", sans-serif;
  text-transform: capitalize !important;
  font-weight: 400 !important;
  color: white;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
  height: 35px;
}
</style>
